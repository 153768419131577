import React, { useState } from "react";
import "assets/styles/pages/sidebar.scss";
import { Link, navigate } from "gatsby";
import treeicon from "assets/images/admin/tree-icon.png";
import breadcumbarrow from "assets/images/admin/breadcumb-arrow-left.png";
import { useForm } from "react-hook-form";
import AdminService from "services/admin/admin.service";
import { Modal, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { saveAs } from "file-saver";
import * as xlsx from "xlsx";
import { useQueryParam, NumberParam } from "use-query-params";

const ErrorJson = [
  {
    siteName: "Site Name is missing",
    siteArea: "Site Area is missing",
    cityName: "City Name Name is missing",
    stateName: "State Name missing",
    isYielding: " is missing Is plant is Yielding",
    yearlyYieldAmount: " is missing yearly yield amount",
    trackingId: "Tracking Id is missing ",
    speciesId: " is missing species name",
    yearOfPlantation: " is missing plantation year",
    species_height: " is Missing species height",
    species_girth: " is Missing species girth",
    plant_height: " ( Plant height should be greater than 0 )",
    plant_girth: " ( Plant girth should be greater than 0 )",
  },
];

export default function UploadgreenAssets() {
  const [adminId] = useQueryParam("id", NumberParam);

  const errorMessage = ErrorJson[0];

  const adminApi = new AdminService();
  const [showAssetDetails, setShowAssetDetails] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);
  const [showErrorBox, setShowErrorBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [greenAssetnDetails, setGreenAssetDetails] = useState();
  const [greenAssetDetailsPayload, setGreenAssetDetailsPayload] = useState();
  const [isUploading, setIsUploading] = useState("no");

  const showError = () => {
    if (errorMsg.length > 0) {
      setShowAssetDetails(false);
      setShowErrorBox(true);
    } else {
      setShowAssetDetails(true);
      setShowErrorBox(false);
    }
  };

  const onSubmit = async () => {
    showError();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  const editGreenAsset = () => {
    setIsLoading(true);
    adminApi
      .editGreenAssetData(greenAssetDetailsPayload, adminId)
      .then((res) => {
        if (res?.data?.isUploaded === true) {
          setIsLoading(false);
          toast.success("Green Asset Data Updated Successfully");
          setTimeout(() => {
            navigate("/admin/greenassetsstatus");
          }, 1000);
        } else {
          setIsLoading(false);
          const binaryString = window.atob(res.data.errorReport);
          const byteArray = Uint8Array.from(binaryString, (value) =>
            value.charCodeAt(0)
          );
          const blob = new Blob([byteArray], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          saveAs(blob, "green_asset_error-report.xlsx");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          toast.error(error);
        }
      });
  };

  const postGreenAsset = () => {
    if (adminId !== undefined || null) {
      editGreenAsset();
    } else {
      postGreenAssetDetails();
    }
  };

  const postGreenAssetDetails = () => {
    setIsLoading(true);
    adminApi
      .postGreenAssetData(greenAssetDetailsPayload)
      .then((res) => {
        if (res?.data?.isUploaded === true) {
          setIsLoading(false);
          toast.success("Green Asset Data Uploaded Successfully");
          setTimeout(() => {
            navigate("/admin/greenassetsstatus");
          }, 1000);
        } else {
          setIsLoading(false);
          const binaryString = window.atob(res.data.errorReport);
          const byteArray = Uint8Array.from(binaryString, (value) =>
            value.charCodeAt(0)
          );
          const blob = new Blob([byteArray], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          saveAs(blob, "green_asset_error-report.xlsx");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          toast.error(error);
        }
      });
  };

  const downloadsamplegreenasset = () => {
    //condition checking to change state from true to false and vice versa
    adminApi
      .downloadGreenAssetExcel()
      .then((data) => {
        if (data.status === 200) {
          const binaryString = window.atob(data.data.fileContent);
          const byteArray = Uint8Array.from(binaryString, (value) =>
            value.charCodeAt(0)
          );

          const blob = new Blob([byteArray], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          saveAs(blob, "green_asset_sample_template.xlsx");
        } else {
          setLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch(() => {});
  };

  const readUploadFile = (e) => {
    while (errorMsg.length > 0) {
      errorMsg.pop();
    }

    e.preventDefault();
    if (e.target.files.length >= 1) {
      if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: "array", cellDates: true });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          let json = xlsx.utils.sheet_to_json(worksheet);

          let greenAssetDetails = json.slice(4);

          var SpeciesTrackingRequest = [];
          var payloadSpeciesTrackingRequest = [];

          for (var assetitem in greenAssetDetails) {
            var plantationRecords = greenAssetDetails[assetitem];

            if (json?.[1]?.__EMPTY_1 === undefined || null) {
              setErrorMsg([errorMessage.siteName]);
            } else {
              if (json?.[1]?.__EMPTY_2 === undefined || null) {
                setErrorMsg([errorMessage.siteArea]);
              } else {
                if (json?.[1]?.__EMPTY === undefined || null) {
                  setErrorMsg([errorMessage.cityName]);
                } else {
                  if (json?.[1]?.["Header data"] === undefined || null) {
                    setErrorMsg([errorMessage.trackingId]);
                  } else {
                    if (
                      plantationRecords?.["Header data"]?.toString() ===
                        undefined ||
                      null
                    ) {
                      errorMsg.push(errorMessage.trackingId);
                    } else {
                      if (plantationRecords?.__EMPTY === undefined || null) {
                        errorMsg.push(
                          "Species with tracking id " +
                            plantationRecords?.["Header data"]?.toString() +
                            errorMessage.speciesId
                        );
                      } else {
                        if (
                          plantationRecords?.__EMPTY_1 === undefined ||
                          null
                        ) {
                          errorMsg.push(
                            "Species with tracking id " +
                              plantationRecords?.["Header data"]?.toString() +
                              errorMessage.yearOfPlantation
                          );
                        } else {
                          if (
                            plantationRecords?.__EMPTY_4 === undefined ||
                            null
                          ) {
                            errorMsg.push(
                              "Species with tracking id " +
                                plantationRecords?.["Header data"]?.toString() +
                                errorMessage.isYielding
                            );
                          } else {
                            if (
                              plantationRecords?.__EMPTY_5 === undefined ||
                              null
                            ) {
                              errorMsg.push(
                                "Species with tracking id " +
                                  plantationRecords?.[
                                    "Header data"
                                  ]?.toString() +
                                  errorMessage.yearlyYieldAmount
                              );
                            } else {
                              if (
                                plantationRecords?.__EMPTY_2?.toString() ===
                                  undefined ||
                                null
                              ) {
                                errorMsg.push(
                                  "Species with tracking id " +
                                    plantationRecords?.[
                                      "Header data"
                                    ]?.toString() +
                                    errorMessage.species_girth
                                );
                              } else {
                                if (plantationRecords?.__EMPTY_2 === 0) {
                                  errorMsg.push(
                                    "Species with tracking id " +
                                      plantationRecords?.[
                                        "Header data"
                                      ]?.toString() +
                                      " has girth as 0." +
                                      errorMessage.plant_girth
                                  );
                                } else {
                                  if (
                                    plantationRecords?.__EMPTY_3?.toString() ===
                                      undefined ||
                                    null
                                  ) {
                                    errorMsg.push(
                                      "Species with tracking id " +
                                        plantationRecords?.[
                                          "Header data"
                                        ]?.toString() +
                                        errorMessage.species_height
                                    );
                                  } else {
                                    if (plantationRecords?.__EMPTY_3 === 0) {
                                      errorMsg.push(
                                        "Species with tracking id " +
                                          plantationRecords?.[
                                            "Header data"
                                          ]?.toString() +
                                          " has height as 0." +
                                          errorMessage.plant_height
                                      );
                                    } else {
                                      let assetDetails = {
                                        trackingId:
                                          plantationRecords?.[
                                            "Header data"
                                          ]?.toString(),
                                        speciesId: plantationRecords?.__EMPTY,
                                        yearOfPlantation:
                                          plantationRecords?.__EMPTY_1,
                                        isYielding:
                                          plantationRecords?.__EMPTY_4 === "YES"
                                            ? true
                                            : false,
                                        yearlyYieldAmount:
                                          plantationRecords?.__EMPTY_5,
                                        attributeMap: {
                                          girth:
                                            plantationRecords?.__EMPTY_2?.toString(),
                                          height:
                                            plantationRecords?.__EMPTY_3?.toString(),
                                        },
                                      };
                                      let payloadAssetDetails = {
                                        trackingId:
                                          plantationRecords?.[
                                            "Header data"
                                          ]?.toString(),
                                        speciesId: Number(
                                          plantationRecords?.__EMPTY
                                            ?.split(" - ")
                                            .pop()
                                        ),
                                        yearOfPlantation:
                                          plantationRecords?.__EMPTY_1,
                                        isYielding:
                                          plantationRecords?.__EMPTY_4 === "YES"
                                            ? true
                                            : false,
                                        yearlyYieldAmount:
                                          plantationRecords?.__EMPTY_5,
                                        attributeMap: {
                                          girth:
                                            plantationRecords?.__EMPTY_2?.toString(),
                                          height:
                                            plantationRecords?.__EMPTY_3?.toString(),
                                        },
                                      };
                                      SpeciesTrackingRequest.push(assetDetails);
                                      payloadSpeciesTrackingRequest.push(
                                        payloadAssetDetails
                                      );
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          let formData = {
            siteName: json?.[1]?.__EMPTY_1,
            siteArea: json?.[1]?.__EMPTY_2?.toString(),
            attributeMap: {
              city: json?.[1]?.__EMPTY,
              state: json?.[1]?.["Header data"],
              country: "India",
            },
            assetSpeciesTrackingRequest: SpeciesTrackingRequest,
          };
          let payloadFormData = {
            siteName: json?.[1]?.__EMPTY_1,
            siteArea: json?.[1]?.__EMPTY_2?.toString(),
            attributeMap: {
              city: json?.[1]?.__EMPTY,
              state: json?.[1]?.["Header data"],
              country: "India",
            },
            assetSpeciesTrackingRequest: payloadSpeciesTrackingRequest,
          };
          setIsUploading("no");
          setGreenAssetDetails(formData);
          setGreenAssetDetailsPayload(payloadFormData);
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
    }
  };

  const emptyUploadedFileData = (e) => {
    while (errorMsg.length > 0) {
      errorMsg.pop();
    }
    e.target.value = "";
    setShowErrorBox(false);
    setShowAssetDetails(false);
  };

  return (
    <div>
      {isLoading ? (
        <Modal show={isLoading} centered className="modal-con">
          <div className="modal-con">
            <Spinner style={{ textAlign: "center" }} animation="border" />
          </div>
        </Modal>
      ) : (
        <>
          <div className="sidebar-content-wrapper">
            <div className="admin-breadcumb-item">
              <span>
                <Link className="" to="/admin/dashboardview/">
                  <img src={treeicon} alt="treeicon" />
                </Link>
              </span>

              <span>
                <img src={breadcumbarrow} alt="arrowicon" />
              </span>

              <h3> Upload Green Asset </h3>
            </div>

            <div className="white-iitiative-wrapper">
              <div className="">
                <h3 className="sub-heading-title">Upload</h3>
              </div>
              <div className="download-excel-btn text-right">
                <button
                  className="btn btn-viewmore save-width"
                  onClick={downloadsamplegreenasset}
                  type="button"
                >
                  Download Excel
                </button>
              </div>
              <div className="register-plantation">
                <div className="container">
                  <div className="row">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="fc-wrapper">
                            <label htmlFor="name" className="form-label">
                              Upload File <i className="hastric-color">*</i>
                            </label>
                            <input
                              type="file"
                              accept=".xls,.xlsx"
                              name="upload"
                              className="form-control "
                              id="upload"
                              // defaultValue={props.upload}
                              {...register("upload", {
                                required: "Upload Green Asset Excel Sheet",
                              })}
                              onClick={(e) => emptyUploadedFileData(e)}
                              onChange={(e) => {
                                setShowErrorBox(false);
                                setShowAssetDetails(false);
                                if (e.target.files.length >= 1) {
                                  setIsUploading("yes");
                                  readUploadFile(e);
                                }
                              }}
                              placeholder=""
                            />
                            {errors.upload && (
                              <span className="form-error">
                                {errors.upload.message}
                              </span>
                            )}

                            {isUploading === "yes" && (
                              <h6>Asset validation In Progress</h6>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="text-right">
                        <Link
                          to="/admin/greenassetsstatus"
                          className="btn btn-cancel mg-right10 margin-btm-10"
                        >
                          Cancel
                        </Link>
                        <button
                          className="btn btn-viewmore save-width"
                          onClick={() => setShowAssetDetails(true)}
                          type="submit"
                          disabled={isUploading === "yes" ? "disabled" : ""}
                        >
                          Upload
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {!showErrorBox && showAssetDetails && greenAssetnDetails && (
                <>
                  <div className="preview-wrapper">
                    <div>
                      <p>
                        <b>Preview</b>
                      </p>
                    </div>
                    <div style={{ marginBottom: "15px" }}>
                      <div className="preview-details-header">
                        <div className="row">
                          <div className="col-md-6">
                            <p className="green-asset-date-time-text">
                              <b>Ashram Site Name :</b>{" "}
                              {greenAssetnDetails?.siteName}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p className="green-asset-date-time-text">
                              <b>Site Area(in Acres) :</b>{" "}
                              {greenAssetnDetails?.siteArea}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="preview-details-header">
                        <div className="row">
                          <div className="col-md-6">
                            <p className="green-asset-date-time-text">
                              <b>City / Town / Village :</b>{" "}
                              {greenAssetnDetails?.attributeMap?.city}
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p className="green-asset-date-time-text">
                              <b>State : </b>
                              {greenAssetnDetails?.attributeMap?.state}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tree-detail-table-wrapper">
                      <table className="tree-detail-table">
                        <tr className="table-row">
                          <th className="head-cell" style={{ width: "8.5%" }}>
                            Tracking ID{" "}
                          </th>
                          <th className="head-cell" style={{ width: "16%" }}>
                            Species Name
                          </th>

                          <th className="head-cell" style={{ width: "9.1%" }}>
                            DBH (in cm)
                          </th>
                          <th className="head-cell" style={{ width: "10.4%" }}>
                            Height (in cm)
                          </th>
                          <th className="head-cell" style={{ width: "17.1%" }}>
                            Is the tree yeilding seeds <br />
                            (For Propagation)?
                          </th>
                          <th className="head-cell" style={{ width: "25.5%" }}>
                            Approximate yearly yeild from the tree <br />
                            (in INR)?
                          </th>
                          <th className="head-cell" style={{ width: "12.4%" }}>
                            Year Of Plantation
                          </th>
                        </tr>
                        {greenAssetnDetails?.assetSpeciesTrackingRequest &&
                          greenAssetnDetails?.assetSpeciesTrackingRequest?.map(
                            (item) => {
                              const assetSpeciesName = item?.speciesId
                                ?.split(" - ")
                                ?.map((item) => item?.trim())?.[0];
                              return (
                                <tr className="table-row">
                                  <td className="body-cell">
                                    {item.trackingId}
                                  </td>
                                  <td className="body-cell">
                                    {assetSpeciesName}
                                  </td>
                                  <td className="body-cell">
                                    {item.attributeMap.girth}
                                  </td>
                                  <td className="body-cell">
                                    {item.attributeMap.height}
                                  </td>
                                  <td className="body-cell">
                                    {item.isYielding === true ? "Yes" : "No"}
                                  </td>
                                  <td className="body-cell">
                                    {item.yearlyYieldAmount}
                                  </td>
                                  <td className="body-cell">
                                    {item.yearOfPlantation}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </table>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      marginTop: "20px",
                    }}
                  >
                    <button
                      className="btn btn-viewmore save-width"
                      style={{ float: "right" }}
                      onClick={() => postGreenAsset()}
                      type="button"
                    >
                      Proceed
                    </button>
                    <button
                      className="btn btn-cancel mg-right10 margin-btm-10"
                      style={{ float: "right" }}
                      onClick={() => navigate("/admin/greenassetsstatus")}
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
              {showErrorBox && (
                <div
                  style={{
                    border: "2px solid Red",
                    padding: "10px",
                    boxShadow: "0px 6px 9px -5px grey",
                    borderRadius: "4px",
                    marginTop: "8px",
                    color: "red",
                  }}
                >
                  {errorMsg?.map((item) => {
                    return <p>* {item}</p>;
                  })}
                </div>
              )}
            </div>
          </div>
          <Modal show={loading} centered className="modal-con">
            <div className="modal-con">
              <Spinner style={{ textAlign: "center" }} animation="border" />
            </div>
          </Modal>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            newestOnTop={false}
            hideProgressBar
            closeOnClick
            rtl={false}
          />
        </>
      )}
    </div>
  );
}
