import React from 'react';

import AdminLayout from '../../adminlayout/index';

import SEO from 'components/seo/index';
// import Side from 'layout/sidebaritem';

import UploadgreenAssets from "components/admin/greenAsset/uploadgreenassetpage";

const Uploadgreenasset = ({ location }) => {
  const { state } = location;
  console.log("{ location }", state);
  return (
    <>
      <SEO title="Forests By Heartfulness admin" />
      <AdminLayout>
        <div>
          <UploadgreenAssets isEdit={state?.isEdit} assetId={state?.assetId} />
        </div>
      </AdminLayout>
    </>
  );
};

export default Uploadgreenasset;
